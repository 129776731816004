.App {
  height: 100%;
}

/* react router transitions :*/
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.flexbox {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: stretch;
}
.flexbox .col {
  flex: 1;
  padding: 20px;
}
